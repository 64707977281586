import store from "../reducers";
import axios from "axios";
import { baseUrl } from "../utils/consts";

export async function fetchStatistics() {
  let { token } = store.getState().auth;
  try {
    let { data } = await axios.get(`${baseUrl}/admin/statistics`, {
      headers: {
        "x-access-token": token,
      },
    });
    return data.data;
  } catch (e) {
    throw e;
  }
}

export async function fetchGamesGraph() {
  let { token } = store.getState().auth;
  try {
    let { data } = await axios.get(`${baseUrl}/admin/statistics/graph/games`, {
      headers: {
        "x-access-token": token,
      },
    });
    return data.data;
  } catch (e) {
    throw e;
  }
}

export async function fetchUsersGraph() {
  let { token } = store.getState().auth;
  try {
    let { data } = await axios.get(`${baseUrl}/admin/statistics/graph/users`, {
      headers: {
        "x-access-token": token,
      },
    });
    return data.data;
  } catch (e) {
    throw e;
  }
}
