import { useTranslation } from "react-i18next";
import { Button, ButtonGroup, Modal, Form, Spinner } from "react-bootstrap";
import React, { useState } from "react";
import { CreateUser } from "../../../../repositories/user.api";
import { ErrorToast } from "../../../../utils/toasts";

function CreateUserModal({
  showCreateUserModal,
  setShowCreateUserModal,
  forceUpdate,
}) {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const Submit = async (e) => {
    e.preventDefault();
    const { name, email, password, phone_number, is_admin, balance } =
      Object.fromEntries(new FormData(e.target).entries());
    try {
      setLoading(true);
      await CreateUser(name, email, password, phone_number, is_admin, balance);
      setShowCreateUserModal({ availability: false });
    } catch (e) {
      ErrorToast("Unable to create new user");
    } finally {
      setLoading(false);
    }
    forceUpdate();
  };

  const onHide = () => {
    showCreateUserModal({ availability: false });
  };

  return (
    <Modal show={showCreateUserModal.availability} onHide={onHide}>
      <Modal.Header>
        <h3>
          {t("create")} {t("user")}
        </h3>
      </Modal.Header>
      <Form onSubmit={Submit}>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label>{t("name")}</Form.Label>
            <Form.Control type="string" name="name" />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>{t("email")}</Form.Label>
            <Form.Control type="string" name="email" />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>{t("password")}</Form.Label>
            <Form.Control type="string" name="password" />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>{t("phone_number")}</Form.Label>
            <Form.Control type="string" name="phone_number" min="13" max="12" />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>{t("balance")}</Form.Label>
            <Form.Control
              type="string"
              name="phone_number"
              min="0"
              max="100000"
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Check type="checkbox" name="is_admin" label={t("is_admin")} />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <ButtonGroup>
            <Button type="submit" disabled={loading} variant="outline-success">
              {loading ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                t("create")
              )}
            </Button>
            <Button variant="outline-light" onClick={onHide}>
              {t("cancel")}
            </Button>
          </ButtonGroup>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default CreateUserModal;
