import React from "react";
import { Container, Navbar, Nav, Button } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import DynamicAI from "../../components/ai.component";

function LandingNavbar() {
  const { t } = useTranslation();
  const [showAiModal, setShowAiModal] = React.useState({ availability: false });
  return (
    <Navbar bg="transparent" expand="md">
      <Container className="landing-nav">
        <Navbar.Brand href="#!">
          <img
            src="/images/logotype.svg"
            alt="dynamicpixels logo"
            onClick={() => setShowAiModal({ availability: true })}
          />{" "}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="navigation">
            <Nav.Link as={NavLink} activeClassName="active" to="/">
              {t("home")}
            </Nav.Link>
            <Nav.Link as={NavLink} activeClassName="active" to="/services">
              {t("services")}
            </Nav.Link>
            <a
              className="nav-link"
              target="_blank"
              activeClassName="active"
              href="https://docs.dynamicpixels.dev"
              rel="noreferrer"
            >
              {t("docs")}
            </a>
            <Nav.Link as={NavLink} activeClassName="active" to="/pricing">
              {t("pricing")}
            </Nav.Link>
            {/*<Nav.Link as={NavLink} activeClassName="active" to="/contact">{t("contact")}</Nav.Link>*/}
          </Nav>
          <Nav className="cta d-flex align-items-center">
            <Nav.Link as={NavLink} to="/login">
              <b>{t("login")}</b>
            </Nav.Link>
            <Nav.Link as={NavLink} to="/register">
              <Button className="pri-btn" variant="light">
                {t("signup")}
              </Button>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>

      <DynamicAI showAiModal={showAiModal} setShowAiModal={setShowAiModal} />
    </Navbar>
  );
}

export default LandingNavbar;
