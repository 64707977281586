import axios from "axios";
import { aiUrl } from "../utils/consts.js";

export async function AskAI(token, question) {
  try {
    let { data } = await axios.post(
      `${aiUrl}/ai/tables`,
      {
        question,
      },
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (e) {
    throw e;
  }
}
