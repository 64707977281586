import React, {useState} from 'react';
import {Button, ButtonGroup, Modal, Spinner} from "react-bootstrap";
import {DeleteUser} from "../../../../repositories/user.api";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {ErrorToast} from "../../../../utils/toasts";

function ConfirmModal({showConfirmModal, setShowConfirmModal, forceUpdate}){
    const {t, i18n} = useTranslation();

    const [loading, setLoading]  = useState(false);

    const Submit = async () => {
        try {
            setLoading(true);
            await DeleteUser(showConfirmModal.userId);
            setShowConfirmModal({availability: false, userId: undefined})
        } catch (e) {
            ErrorToast("Unable to delete user")
        } finally {
            setLoading(false);
        }
        forceUpdate()
    }

    const onHide = () => {
        setShowConfirmModal({availability: false})
    }

    return <Modal
        show={showConfirmModal.availability}
        onHide={onHide}
    >
        <Modal.Header><h3>{t("confirm")}</h3></Modal.Header>
        <Modal.Body>
            <p>{t("confirm_action_note")}</p>
        </Modal.Body>

        <Modal.Footer>
            <ButtonGroup>
            <Button
                onClick={Submit}
            disabled={loading}
                variant="outline-success"
            >{
                loading?
                    <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    />
                    :
                    t("confirm")
            }</Button>
            <Button variant="outline-light" onClick={onHide}>{t("cancel")}</Button>
            </ButtonGroup>
        </Modal.Footer>
    </Modal>
}

export default ConfirmModal;