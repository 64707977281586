import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Col, Container, Form, Row } from "react-bootstrap";
import { fetchGames } from "../../../repositories/game.api";
import PaginationContainer from "../../../components/pagination.component";
import Table from "react-bootstrap/Table";

function AdminGames() {
  const { t } = useTranslation();
  const params = useParams();

  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [allRowCount, setAllRowCount] = useState(0);
  const [page, setPage] = useState(1);
  const [list, setList] = useState([]);
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(25);

  useEffect(() => {
    fetchList();
  }, []);

  const fetchList = async (page = 1, query = "") => {
    let skip = (page - 1) * 25;

    let { list, count } = await fetchGames(query, skip, limit);
    setList(list);
    setPage(page);
    setSkip(skip);
    setAllRowCount(count);
  };

  const handleSearchQuery = async (e) => {
    setSearchQuery(e.target.value);
    if (e.target.value.length > 3) {
      await fetchList(e.target.value, 0, 50);
    }
  };

  return (
    <>
      <div className="mb-3" style={{ backgroundColor: "black" }}>
        <Container>
          <Row style={{ padding: "3em 0" }}>
            <Col
              style={{
                backgroundColor: "black",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
              className="mb-4"
            >
              <h3 style={{ marginLeft: 10 }}>{t("games")}</h3>
            </Col>

            <Col
              sm={10}
              lg={6}
              style={{
                backgroundColor: "black",
                display: "flex",
                flexDirection: "row",
                justifyContent: "end",
                alignItems: "center",
              }}
              className={"mb-4"}
            ></Col>
            <Col sm={{ span: 12 }}>
              <Form.Group style={{ margin: "0 10px" }}>
                <Form.Control
                  type="text"
                  className="block-text-input"
                  placeholder="Search..."
                  value={searchQuery}
                  onChange={handleSearchQuery}
                />
              </Form.Group>
            </Col>
          </Row>
        </Container>
      </div>
      <Container style={{ paddingTop: 40 }}>
        <Table responsive>
          <thead>
            <tr>
              <th>{t("name")}</th>
              <th>{t("status")}</th>
              <th>{t("created_at")}</th>
              <th>{t("actions")}</th>
            </tr>
          </thead>
          <tbody>
            {list.map((game) => (
              <tr key={`game=${game._id}`}>
                <td>{game.name}</td>
                <td>{game.status}</td>
                <td>{game.created_at}</td>
                <td></td>
              </tr>
            ))}
          </tbody>
        </Table>

        {allRowCount > 0 && (
          <PaginationContainer
            className="mb-5"
            currentPage={page}
            totalCount={allRowCount}
            limit={25}
            action={fetchList}
          />
        )}
      </Container>
    </>
  );
}

export default AdminGames;
