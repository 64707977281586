import { Button, InputGroup, Modal, Form } from "react-bootstrap";
import "./ai.style.css";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { AskAI } from "../repositories/ai.api";
import { ErrorToast } from "../utils/toasts";
import { useSelector } from "react-redux";

export default function DynamicAI({ showAiModal, setShowAiModal }) {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const selectedGame = useSelector((state) => state.games.selectedGame);

  const AskQuestion = async (e) => {
    e.preventDefault();

    setLoading(true);
    let { question } = Object.fromEntries(new FormData(e.target).entries());

    try {
      setMessages((messages) => [
        ...messages,
        { role: "user", message: question },
      ]);
      let resposne = await AskAI(selectedGame.cloud_key, question);
      setMessages((messages) => [...messages, resposne]);
    } catch (e) {
      ErrorToast("Cant go to checkout page");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      show={showAiModal.availability}
      onHide={() => setShowAiModal({ availability: false })}
      className={`ai-modal ${loading ? " loading" : ""}`}
    >
      <form onSubmit={AskQuestion}>
        <Modal.Body>
          {messages.length === 0 && (
            <div
              className="d-flex flex-column justify-content-center align-items-center"
              style={{ margin: "50px 0" }}
            >
              <i
                className="fa-solid fa-wand-magic-sparkles mb-3"
                style={{ fontSize: "3em" }}
              ></i>
              <h4>{t("dynamic_ai_cta")}</h4>
            </div>
          )}
          <div className="msgs d-flex flex-column">
            {messages.map((msg, inx) => (
              <p
                className={`msg ${
                  msg.role === "user" ? "msg-user" : "msg-assistant"
                }`}
                key={`msg-${inx}`}
              >
                {msg.message}
              </p>
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <InputGroup className="mb-3">
            <Form.Control
              placeholder="Enter what you want..."
              name="question"
            />
            <Button variant="success" type="submit" disabled={loading}>
              <i className="fa-solid fa-arrow-right"></i>
            </Button>
          </InputGroup>
        </Modal.Footer>
      </form>
    </Modal>
  );
}