import { Card, Col, Container, Row } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import {
  fetchGamesGraph,
  fetchUsersGraph,
  fetchStatistics,
} from "../../repositories/admin.api";
import {
  Bar,
  ComposedChart,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

export default function AdminOverview() {
  const [statistics, setStatistics] = useState({});
  const [games, setGames] = useState([]);
  const [usersRegister, setUsersRegister] = useState([]);
  const [usersLogin, setUsersLogin] = useState([]);

  useEffect(() => {
    (async () => {
      setStatistics(await fetchStatistics());

      let gamesGraphData = await fetchGamesGraph();
      setGames(
        gamesGraphData.map((item) => {
          return {
            name: `${item._id.year}/${item._id.month}`,
            count: item.count,
          };
        }),
      );

      let { registering, login } = await fetchUsersGraph();
      setUsersRegister(
        registering.map((item) => {
          return {
            name: `${item._id.year}/${item._id.month}`,
            count: item.count,
          };
        }),
      );
      setUsersLogin(
        login.map((item) => {
          return {
            name: `${item._id.year}/${item._id.month}`,
            count: item.count,
          };
        }),
      );
    })();
  }, []);

  return (
    <Container>
      <Row className="mb-5">
        <Col sm={12} lg={3}>
          <Card>
            <Card.Body>
              <h3 style={{ textAlign: "2em" }}>{statistics.games}</h3>
              <span>Games</span>
              <hr />

              <h3 style={{ textAlign: "2em" }}>
                {statistics.runningGamesCount}
              </h3>
              <span>Running Games</span>
              <hr />

              <h3 style={{ textAlign: "2em" }}>{statistics.nonPaygGames}</h3>
              <span>Revenue Share Games</span>
            </Card.Body>
          </Card>
        </Col>
        <Col className="d-flex flex-column justify-content-center">
          <h5>Submitted Game / Month</h5>
          <ResponsiveContainer>
            <ComposedChart data={games}>
              <XAxis dataKey="name" scale="band" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="count" fill="#5fc9f8" />
            </ComposedChart>
          </ResponsiveContainer>
        </Col>
      </Row>
      <Row className="mb-5">
        <Col sm={12} lg={3}>
          <Card>
            <Card.Body>
              <h3 style={{ textAlign: "2em" }}>{statistics.users}</h3>
              <span>All Users</span>

              <hr />

              <h3 style={{ textAlign: "2em" }}>
                {statistics.negBalanceUsersCount}
              </h3>
              <span>Negative Balance Users</span>

              <hr />

              <h3 style={{ textAlign: "2em" }}>
                {statistics.notActiveUsersCount}
              </h3>
              <span>Not-active Users</span>
            </Card.Body>
          </Card>
        </Col>
        <Col className="d-flex flex-column justify-content-center">
          <h5>Registered Users / Month</h5>
          <ResponsiveContainer>
            <ComposedChart data={usersRegister}>
              <XAxis dataKey="name" scale="band" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="count" fill="#42a5f5" />
            </ComposedChart>
          </ResponsiveContainer>
        </Col>
      </Row>
      <Row className="mb-5">
        <Col sm={12} lg={3}>
          <Card>
            <Card.Body>
              <h3 style={{ textAlign: "2em" }}>{statistics.paymentsCount}</h3>
              <span>Payments</span>

              <hr />

              <h3 style={{ textAlign: "2em" }}>{statistics.invoicesCount}</h3>
              <span>Invoices</span>

              <hr />

              <h3 style={{ textAlign: "2em" }}>
                {statistics.balanceAverage} $
              </h3>
              <span>Balance Average</span>
            </Card.Body>
          </Card>
        </Col>
        <Col className="d-flex flex-column justify-content-center">
          <h5>Login / Month</h5>
          <ResponsiveContainer>
            <ComposedChart data={usersLogin}>
              <XAxis dataKey="name" scale="band" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="count" fill="#42a5f5" />
            </ComposedChart>
          </ResponsiveContainer>
        </Col>
      </Row>
      <Row className="mb-5">
        <Col sm={12} lg={3}>
          <Card>
            <Card.Body></Card.Body>
          </Card>
        </Col>
        <Col></Col>
      </Row>
    </Container>
  );
}
