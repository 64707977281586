import axios from "axios";
import { baseUrl } from "../utils/consts.js";
import store from "../reducers";

export async function fetchUserInfo(userId) {
  let { token } = store.getState().auth;
  try {
    let { data } = await axios.get(`${baseUrl}/user/${userId}`, {
      headers: {
        "x-access-token": token,
      },
    });
    return data.data;
  } catch (e) {
    throw e;
  }
}

export async function ChangePassword(
  current_password,
  new_password,
  re_new_password,
) {
  let { token } = store.getState().auth;
  try {
    let { data } = await axios.post(
      `${baseUrl}/user/change-password`,
      {
        current_password,
        new_password,
        re_new_password,
      },
      {
        headers: {
          "x-access-token": token,
        },
      },
    );
    return data.data;
  } catch (e) {
    throw e;
  }
}

export async function fetchUsers(query = "", skip = 0, limit = 50) {
  let { token } = store.getState().auth;
  try {
    let { data } = await axios.get(
      `${baseUrl}/user/find?skip=${skip}&limit=${limit}&query=${query}`,
      {
        headers: {
          "x-access-token": token,
        },
      },
    );
    return data.data;
  } catch (e) {
    throw e;
  }
}
export async function CreateUser(
  name,
  email,
  password,
  phone_number,
  is_admin,
  balance,
) {
  let { token } = store.getState().auth;
  try {
    let { data } = await axios.post(
      `${baseUrl}/user`,
      { name, email, password, phone_number, is_admin, balance },
      {
        headers: {
          "x-access-token": token,
        },
      },
    );
    return data.data;
  } catch (e) {
    throw e;
  }
}

export async function updateUserInfo(name, email, website, phone_number) {
  let { token } = store.getState().auth;
  try {
    let { data } = await axios.put(
      `${baseUrl}/user`,
      { name, email, website, phone_number },
      {
        headers: {
          "x-access-token": token,
        },
      },
    );
    return data.data;
  } catch (e) {
    throw e;
  }
}

export async function fetchUserNotification() {
  let { token } = store.getState().auth;
  try {
    let { data } = await axios.get(`${baseUrl}/user/notification`, {
      headers: {
        "x-access-token": token,
      },
    });
    return data.data;
  } catch (e) {
    throw e;
  }
}

export async function updateUserNotification(
  sms_login,
  sms_balance,
  sms_deactive,
  email_login,
  email_balance,
  email_deactive,
) {
  let { token } = store.getState().auth;
  try {
    let { data } = await axios.put(
      `${baseUrl}/user/notification`,
      {
        sms_login,
        sms_balance,
        sms_deactive,
        email_login,
        email_balance,
        email_deactive,
      },
      {
        headers: {
          "x-access-token": token,
        },
      },
    );
    return data.data;
  } catch (e) {
    throw e;
  }
}

export async function DeleteUser(userId) {
  let { token } = store.getState().auth;
  try {
    let { data } = await axios.delete(`${baseUrl}/user/${userId}`, {
      headers: {
        "x-access-token": token,
      },
    });
    return data.data;
  } catch (e) {
    throw e;
  }
}

export async function ChargeBalance(amount) {
  let { token } = store.getState().auth;
  try {
    let { data } = await axios.post(
      `${baseUrl}/financial/charge`,
      {
        amount,
      },
      {
        headers: {
          "x-access-token": token,
        },
      },
    );
    return data.data;
  } catch (e) {
    throw e;
  }
}

export async function RedeemGiftCode(code) {
  let { token } = store.getState().auth;
  try {
    let { data } = await axios.post(
      `${baseUrl}/financial/giftcode`,
      {
        code,
      },
      {
        headers: {
          "x-access-token": token,
        },
      },
    );
    return data.data;
  } catch (e) {
    throw e;
  }
}
