import axios from 'axios';
import { baseUrl } from "../utils/consts.js";
import store from "../reducers";

export async function fetchGames(query= "", skip = 0, limit=50) {
    let {token} = store.getState().auth;
    try {
        let { data } = await axios.get(`${baseUrl}/game?skip=${skip}&limit=${limit}&query=${query}`,{
            headers:{
                "x-access-token": token,
            }
        });
        return data.data;
    } catch (e) {
        if(e.response && e.response.data)
            throw new Error(e.response.data.msg)
        throw e
    }
}

export async function CreateGame(name, category, server_type) {
    let {token} = store.getState().auth;
    try {
        let { data } = await axios.post(`${baseUrl}/game`,{
            name, category, server_type
        },{
            headers:{
                "x-access-token": token,
            }
        });
        return data.data;
    } catch (e) {
        if(e.response && e.response.data)
            throw new Error(e.response.data.msg)
        throw e
    }
}

export async function fetchGameInfo(gameId) {
    let {token} = store.getState().auth;
    try {
        let { data } = await axios.get(`${baseUrl}/game/${gameId}`,{
            headers:{
                "x-access-token": token,
            }
        });
        return data.data;
    } catch (e) {
        throw e
    }
}

export async function updateGameInfo(gameId, name, category) {
    let {token} = store.getState().auth;
    try {
        let { data } = await axios.put(`${baseUrl}/game/${gameId}`, {name, category}, {
            headers:{
                "x-access-token": token,
            }
        });
        return data.data;
    } catch (e) {
        throw e
    }
}

export async function updateConnectionTypes(gameId, connectionTypes) {
    let {token} = store.getState().auth;
    try {
        let { data } = await axios.put(`${baseUrl}/game/${gameId}/settings/connection-type`, connectionTypes, {
            headers:{
                "x-access-token": token,
            }
        });
        return data.data;
    } catch (e) {
        throw e
    }
}

export async function RevokeGameSecret(gameId) {
    let {token} = store.getState().auth;
    try {
        let { data } = await axios.post(`${baseUrl}/game/${gameId}/settings/revoke`, {}, {
            headers:{
                "x-access-token": token,
            }
        });
        return data.data;
    } catch (e) {
        throw e
    }
}

export async function purgeGame(gameId) {
    let {token} = store.getState().auth;
    try {
        let { data } = await axios.delete(`${baseUrl}/game/${gameId}/settings/purge`, {
            headers:{
                "x-access-token": token,
            }
        });
        return data.data;
    } catch (e) {
        throw e
    }
}

export async function fetchOtpProvider(gameId) {
    let {token} = store.getState().auth;

    try {
        let {data} = await axios.get(`${baseUrl}/game/${gameId}/settings/integration/otp`,
            {
                headers: {
                    "x-access-token": token,
                }
            }
        );

        return data.data;
    } catch (e) {
        throw e;
    }
}

export async function updateOtpProvider(gameId, body) {
    let {token} = store.getState().auth;

    try {
        let {data} = await axios.put(`${baseUrl}/game/${gameId}/settings/integration/otp`,
            body,
            {
                headers: {
                    "x-access-token": token,
                }
            }
        );

        return data.data;
    } catch (e) {
        throw e;
    }
}

export async function fetchGAuthProvider(gameId) {
    let {token} = store.getState().auth;

    try {
        let {data} = await axios.get(`${baseUrl}/game/${gameId}/settings/integration/gauth`,
            {
                headers: {
                    "x-access-token": token,
                }
            }
        );

        return data.data;
    } catch (e) {
        throw e;
    }
}

export async function updateGAuthProvider(gameId, body) {
    let {token} = store.getState().auth;

    try {
        let {data} = await axios.put(`${baseUrl}/game/${gameId}/settings/integration/gauth`,
            body,
            {
                headers: {
                    "x-access-token": token,
                }
            }
        );

        return data.data;
    } catch (e) {
        throw e;
    }
}
