import React from 'react';
import {Col, Pagination} from "react-bootstrap";

function PaginationContainer({currentPage, totalCount, limit=25, action}) {
    let pagesCount = Math.ceil(totalCount / limit);

    return <Col sm={12}>
        <Pagination>
            {currentPage - 1 > 0 && <Pagination.Prev onClick={()=>action(currentPage - 1)}/>}
            {currentPage - 3 > 0 && <Pagination.Item onClick={()=>action(1)}>{1}</Pagination.Item>}

            {(currentPage - 3) > 0 && <Pagination.Ellipsis/>}
            {(currentPage - 2 > 0 && currentPage - 3 <= 0) && <Pagination.Item onClick={() => action(currentPage - 2)}>{currentPage - 2}</Pagination.Item>}
            {(currentPage - 1) > 0 && <Pagination.Item onClick={()=>action(currentPage - 1)}>{currentPage - 1}</Pagination.Item>}

            <Pagination.Item active>{currentPage}</Pagination.Item>

            {pagesCount >= (currentPage + 1) && <Pagination.Item onClick={()=> action(currentPage + 1)}>{currentPage + 1}</Pagination.Item>}
            {(pagesCount >= (currentPage + 2) && pagesCount >= currentPage) && <Pagination.Item onClick={() => action(currentPage + 2)}>{currentPage + 2}</Pagination.Item>}
            {pagesCount >= (currentPage + 3) && <Pagination.Ellipsis/>}

            {pagesCount >= (currentPage + 3) && <Pagination.Item onClick={() => action(pagesCount)}>{pagesCount}</Pagination.Item>}
            {currentPage + 1 <= pagesCount && <Pagination.Next onClick={()=> action(currentPage + 1)}/>}
        </Pagination>
    </Col>
}

export default PaginationContainer;